import { graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"
import showdown from "showdown"
import "../scss/all.scss"
import BackgroundImage from 'gatsby-background-image'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const converter = new showdown.Converter()


const IndexPageTemplate = ({ content }) => (
  <>
    <div>
      <BackgroundImage
      Tag="div"
      className="hero"
      fluid={content.heroSection.image.childImageSharp.fluid}
      backgroundColor={`#000000`}
    >
      <div className="video-overlay">
        {/* <video width="900" autoplay="autoplay" muted>
          <source src="/assets/logo-video.mp4" type="video/mp4"/>
          Your browser does not support the video tag.
        </video> */}
         <img
          className="video-overlay__logo"
          src="/assets/logo-full.svg"
          alt="Clique UK Logo"
        ></img>
      </div>
      <img
          className="logo"
          src="/assets/logo.svg"
          alt="Clique UK Logo"
        ></img>
        <div className="row g-0">
          <div className="col-12 col-mb-6 px-mb-60 px-tb-120 py-60 d-flex flex-column align-items-center">
            <h2 className="text-center">
              {content.heroSection.createSection.title}
            </h2>
            <p className="text-center">
              {content.heroSection.createSection.text}
            </p>
            <a
              className="btn"
              href={content.heroSection.createSection.button.url}
              target="_blank"
              rel="noreferrer"
            >
              {content.heroSection.createSection.button.text}
            </a>
          </div>
          <div className="col-12 col-mb-6 px-mb-60 px-tb-120 py-60 d-flex flex-column align-items-center">
            <h2 className="text-center">
              {content.heroSection.locateSection.title}
            </h2>
            <p className="text-center">
              {content.heroSection.locateSection.text}
            </p>
            <a
              className="btn"
              href={content.heroSection.locateSection.button.url}
              target="_blank"
              rel="noreferrer"
            >
              {content.heroSection.locateSection.button.text}
            </a>
          </div>
        </div>
        {/* <img
          className="scroll"
          src="/assets/scroll.svg"
          alt="Scroll to content"
        ></img> */}
        <div className="hero__fade"></div>
      </BackgroundImage>
      <div className="body-wrapper">
        <div className="row g-0">
          <div className="col">
            <div className="small-container">
              <Accordion allowZeroExpanded="true">
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h1 className="h3">{content.aboutSection.title}
                      <img
                        className="icon icon--up"
                        src="/assets/chevron-up.svg"
                        alt="Scroll to content"
                      ></img>
                      <img
                        className="icon icon--down"
                        src="/assets/chevron-down.svg"
                        alt="Scroll to content"
                      ></img>
                      </h1>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: converter.makeHtml(content.aboutSection.content),
                      }}
                    ></div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
            <Img
              className="img mt-120"
              fluid={content.aboutSection.image.childImageSharp.fluid}
              alt="Alt text here"
            />
          </div>
        </div>
        <div className="row g-0 mt-120">
          <div className="col-12 col-mb-4 mb-60 mb-mb-0 d-flex flex-column align-items-center">
            <h3 className="h4">Instagram</h3>
            <a
              className="p color--red text-center mb-0"
              href={content.socialSection.instagramLink}
            >
              {content.socialSection.instagramTitle}
            </a>
          </div>
          <div className="col-12 col-mb-4 mb-60 mb-mb-0 d-flex flex-column align-items-center">
            <h3 className="h4">Phone</h3>
            <a className="p color--red text-center mb-0" href={content.socialSection.phoneLink}>
              {content.socialSection.phoneTitle}
            </a>
          </div>
          <div className="col-12 col-mb-4 d-flex flex-column align-items-center">
            <h3 className="h4">Email</h3>
            <a className="p color--red text-center mb-0" href={content.socialSection.emailLink}>
              {content.socialSection.emailTitle}
            </a>
          </div>
        </div>
        <div className="row g-0 mt-120">
          <div className="col">
            <Img
            className="img"
              fluid={content.socialSection.image.childImageSharp.fluid}
              alt="Alt text here"
            />
          </div>
        </div>
        <div className="row g-0 mt-120">
          <div className="col">
            <div className="small-container">
            <Accordion allowMultipleExpanded="true" allowZeroExpanded="true" className="faqs">
            <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                    <h3 className="h4">FAQs
                    <img
                            className="icon icon--up"
                            src="/assets/chevron-up.svg"
                            alt="Scroll to content"
                          ></img>
                          <img
                            className="icon icon--down"
                            src="/assets/chevron-down.svg"
                            alt="Scroll to content"
                          ></img></h3>
           
                  </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                  {content.faqsSection.map(function (item, index) {
              return (
                
                      <div key={index}>
                      <h5 class="h5">{item.question}
                      </h5>
                      <p className="mb-30">{item.answer}</p>
                    </div>
                      )
                    })}
                  </AccordionItemPanel>
                </AccordionItem>
            
            </Accordion>
            </div>
            <div className="spacer"></div>
          </div>
        </div>
      </div>
    </div>
  </>
)

IndexPageTemplate.propTypes = {
  content: PropTypes.object,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <div>
      <Helmet>
        <title>{frontmatter.seoTitle}</title>
        <meta name="description" content={frontmatter.seoDescription} />
        <link rel="preconnect" href="https://fonts.googleapis.com"></link>
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossorigin
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Barlow+Condensed&family=Bebas+Neue&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>

      <IndexPageTemplate content={frontmatter} />
    </div>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        templateKey
        seoTitle
        seoDescription
        heroSection {
          createSection {
            title
            text
            button {
              url
              text
            }
          }
          locateSection {
            title
            text
            button {
              url
              text
            }
          }
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        aboutSection {
          title
          content
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        socialSection {
          instagramTitle
          instagramLink
          phoneTitle
          phoneLink
          emailTitle
          emailLink
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        faqsSection {
          question
          answer
        }
      }
    }
  }
`
